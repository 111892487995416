* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #fcfcfc;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #999999;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: inset 0 0 20px 20px transparent !important;
}
